import React from 'react';
import { useTable } from 'react-table';
import './FilesTable.scss';

export const FilesTable = ({ columns, data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

  return (
    <table className="table-container" {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()} className="table-header">
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr className="table-tr" {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <td className="table-cell" {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

FilesTable.defaultProps = {
  data: [
    { date: '01/01/05', status: 'error', filename: 'toto.png' },
    { date: '01/01/05', status: 'done', filename: 'toto.png' },
    { date: '01/01/05', status: 'done', filename: 'toto.png' },
    { date: '01/01/05', status: 'done', filename: 'toto.png' },
  ],
};
