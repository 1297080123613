import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { CloudArrowUp, Plus } from '@styled-icons/bootstrap';
import './UploadFiles.scss';

export const UploadFiles = () => {
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;
        console.log(binaryStr);
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      style={{ display: 'flex', width: 680 }}
      {...getRootProps({ onclick: (event) => console.log('Bien cliqué !') })}
    >
      <div className="upload-files-zone">
        <input {...getInputProps()} />
        <button type="button" className="upload-files-new">
          <Plus size={'28'} />
          <p>Nouveau</p>
        </button>
        <div className="upload-files-dnd">
          <CloudArrowUp size={'50'} color="#ff9900" />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>
              Glissez et déposez le fichier, ou cliquez sur Nouveau. <br />
              <em>Est attendu un fichier au format *.csv uniquement</em>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
