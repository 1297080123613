import { BarChart, Calculator, CloudArrowUp, People, Sliders } from '@styled-icons/bootstrap';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { AuthRoute } from './components/AuthRoute';
import { RoleRoute } from './components/RoleRoute';
import { ROLES_USER } from './constants/constants';
import { AccountingFile } from './views/AccountingFile/AccountingFile';
import { AddUser } from './views/AddUser';
import { Budget } from './views/Budget';
import ComputationsParameters from './views/ComputationsParameters';
import { Error404 } from './views/Error404';
import { ForgotPassword } from './views/ForgotPassword';
import { Home } from './views/Home';
import { Login } from './views/Login';
import { ManageUsers } from './views/ManageUsers';
import { ResetPassword } from './views/ResetPassword';
import { Simulation } from './views/Simulation';
import { UpdateUser } from './views/UpdateUser';

// Display of views according to roles
// ADMIN (all), DIRECTOR (all), ASSOCIATE (Simulation and Budget)
export const routes = {
  [ROLES_USER.ASSOCIATE]: [
    {
      path: '/',
      exact: true,
      component: Home,
    },
    {
      path: '/simulation',
      exact: true,
      component: Simulation,
      dom: {
        label: 'Simulation',
        icon: Sliders,
      },
    },
    {
      path: '/budget',
      exact: true,
      component: Budget,
      dom: {
        label: 'Budget',
        icon: BarChart,
      },
    },
  ],
  [ROLES_USER.DIRECTOR]: [
    {
      path: '/accountingFile',
      exact: true,
      component: AccountingFile,
      dom: {
        label: 'Charger des frais réels',
        icon: CloudArrowUp,
      },
    },
    {
      path: '/ComputationsParameters',
      exact: true,
      component: ComputationsParameters,
      dom: {
        label: 'Paramètres de calcul',
        icon: Calculator,
      },
    },
    {
      path: '/userManagement',
      exact: true,
      component: ManageUsers,
      dom: {
        label: 'Gestion des utilisateurs',
        icon: People,
      },
    },
    {
      path: '/updateUser',
      exact: true,
      component: UpdateUser,
    },
    {
      path: '/addUser',
      exact: true,
      component: AddUser,
    },
  ],
  [ROLES_USER.ADMIN]: [],
};

export default () => {
  return (
    <Router>
      <Switch>
        <AuthRoute exact path="/login" component={Login} />
        <Route exact path="/recovery" component={ForgotPassword} />
        <Route exact path="/resetPassword" component={ResetPassword} />
        {Object.keys(routes).map((role) => {
          return Object.keys(routes[role]).map((key) => (
            <RoleRoute key={`${role}-route-${key}`} role={role} {...routes[role][key]} />
          ));
        })}
        <Route path="/" component={Error404} />
      </Switch>
    </Router>
  );
};
