import { Moon, Check2, Eye, X } from '@styled-icons/bootstrap';
import React from 'react';
import { UploadFiles } from '../../components/UploadFiles';
import { FilesTable } from '../../components/FilesTable';
import { Menu } from '../../components/Menu';
import './AccountingFile.scss';

export const AccountingFile = () => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Dates',
        accessor: 'date', // accessor is the "key" in the data
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row }) => {
          return (
            <div>
              {row.values.status === 'done' ? (
                <Check2 size={'30'} color="#a6ff00" />
              ) : (
                <X size={'30'} color="#ff1900" />
              )}
            </div>
          );
        },
      },
      {
        Header: 'Documents',
        accessor: 'filename',
      },
      {
        Header: () => null, // No header
        id: 'actions', // It needs an ID
        Cell: ({ row }) => {
          return (
            <div style={{ marginLeft: 2, marginRight: 2 }}>
              <div onClick={() => console.log(row)}>
                <Eye />
              </div>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <div className="accounting-file-container">
      <Menu />
      <main className="accounting-file-main-wrapper">
        <h1>Charger une feuille de frais réels</h1>
        {/* <hr></hr> */}
        <div className="accounting-file-wrapper">
          <section className="accounting-file-section">
            <div className="accounting-file-section-title">
              <h3>Chargement du fichier comptable</h3>
              <a href="##">Règles d'acceptation d'un fichier comptable</a>
            </div>
            <div className="accounting-file-content">
              <div className="accounting-file-import">
                <div className="accounting-file-input">
                  <label>1. Nommez le document</label>
                  <input label="Nommez le fichier" type="text" placeholder="Ex : Réels d'Août 2021"></input>
                </div>
                <div className="accounting-file-upLoad">
                  <label>2. Importez le document</label>
                  <div className="accounting-file-upload-files">
                    <UploadFiles />
                  </div>
                </div>
              </div>
              <div className="accounting-file-animation">
                <div className="accounting-file-animText">
                  <em>C'est calme par ici ...</em>
                </div>
                <div className="accounting-file-animating">
                  <Moon size={'30'} color="#8B8B8B" />
                </div>
              </div>
            </div>
          </section>
          <section className="accounting-file-section">
            <h3>Historique des imports</h3>
            <div className="accounting-file-content">
              <div className="accounting-file-table">
                <FilesTable columns={columns} />
              </div>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
};
